<template>
  <v-container
    id="about"
    tag="section"
  >
    <base-subheading><h4 class="text-center">OFERTA NABYCIA AKCJI W SPÓŁCE POD FIRMĄ:</h4><h5 class="text-center">FP SPOMAX S.A. Z SIEDZIBĄ W OSTROWIE WIELKOPOLSKIM</h5></base-subheading>
    <v-col cols="10" offset-md="1">
            
      <p class="text-justify" style="font-size:14px"><i>
        Działając w imieniu i na rzecz spółki pod firmą: FABRYKA MACHIN sp. z o.o. z siedzibą w Warszawie, pod adresem: Aleje Jerozolimskie nr 65/79, 00-697 Warszawa (KRS: 0000978763, REGON: 522400818, NIP: 7011094511; dalej: „FABRYKA MACHIN sp. z o.o.”), jako akcjonariusz spółki pod firmą: FP SPOMAX S.A. z siedzibą w Ostrowie Wielkopolskim, pod adresem: ul. Kaliska nr 61/63, 63-400 Ostrów Wielkopolski (KRS: 0000045857, REGON: 250569048, NIP: 6220005801; dalej: „FP SPOMAX S.A.” lub „Spółka”), na podstawie art. 30 ust. 30.2. Statutu Spółki, niniejszym oferuję nabycie akcji na okaziciela (dalej także: „Akcje”) zgodnie z niżej opisanymi warunkami (dalej także: „Oferta”).
        <ol>
          <li>FABRYKA MACHIN sp. z o.o. oferuje nabycie Akcji w liczbie nie większej niż 300 408,32 Akcji posiadanych przez Akcjonariuszy Spółki oferujących sprzedaż Akcji.</li>
          <li>Cena Zakupu Akcji wynosi 0,01 PLN (słownie: zero złotych jeden grosz) za jedną Akcję.</li>
          <li>Maksymalna wartość akcji objętych Ofertą nie przekroczy kwoty w wysokości 3 004,08 PLN (słownie: trzy tysiące cztery złotych osiem groszy).</li>
          <li>Akcje zostaną nabyte przez FABRYKA MACHIN sp. z o.o. od Akcjonariuszy Spółki, którzy w terminie do dn. 31.01.2024r., złożą ofertę sprzedaży Akcji w odpowiedzi na Ofertę (po uwzględnieniu ewentualnej redukcji zgodnie z postanowieniami pkt 10 nin. Oferty).</li>
          <li>Podmiotami uprawnionymi do składania ofert sprzedaży Akcji w ramach Oferty są wszyscy Akcjonariusze posiadający Akcje Spółki w chwili przyjmowania ofert sprzedaży przez FABRYKA MACHIN sp. z o.o.</li>
          <li>Akcjonariusz w ofercie sprzedaży Akcji oświadczy, że:
              <ol type="a">
                  <li>Akcje przysługują Akcjonariuszowi niepodzielnie i są opłacone w całości;</li>
                  <li>Akcje nie uległy umorzeniu, ani też nie podjęto żadnych działań, które mogłyby skutkować umorzeniem Akcji;</li>
                  <li>Posiadanie Akcji przez Akcjonariusza nie wiąże się z żadnymi obowiązkami wobec Spółki, poza określonymi przepisami prawa powszechnie obowiązującego;</li>
                  <li>Jest uprawniony do zbycia Akcji na rzecz FABRYKA MACHIN sp. z o.o., a zawarcie i wykonanie umowy sprzedaży, nie będzie stanowić naruszenia postanowień Statutu Spółki, ani przepisów prawa;</li>
                  <li>Nie jest wymagana zgoda organów Akcjonariusza na zbycie Akcji (o ile dotyczy);</li>
                  <li>Zbycie Akcji nie jest w żaden sposób ograniczone na mocy Statutu Spółki, ani innych zobowiązań, w szczególności nie jest ono obciążone prawem pierwokupu;</li>
                  <li>Akcje nie zostały zajęte w postępowaniu zabezpieczającym, egzekucyjnym, bądź innym podobnym;</li>
                  <li>Akcje nie są obciążone żadnymi niespełnionymi świadczeniami należnymi Spółce;</li>
                  <li>Akcje są wolne od wad prawnych jak również na Akcjach nie ciążą żadne obciążenia, w szczególności są one wolne od jakichkolwiek roszczeń osób trzecich, w tym z tytułu zastawu lub użytkowania, ani że nie zostały podjęte jakiekolwiek działania celem ustanowienia takich praw osób trzecich;</li>
                  <li>W stosunku do Akcji nie toczy się żadne postępowanie przed sądem powszechnym, administracyjnym ani polubownym, organem egzekucyjnym, organem administracji publicznej;</li>
                  <li>Spółka nie posiada, nie posiadała w przeszłości ani nie zobowiązała się nabyć udziałów lub akcji jakiejkolwiek innej spółki, ani nie zobowiązała się połączyć z jakąkolwiek inną spółką lub podmiotem.</li>
              </ol>
          </li>
          <li>Wszystkie nabyte Akcje w liczbie ustalonej zgodnie z zasadami określonymi powyżej, zostaną opłacone przez FABRYKA MACHIN sp. z o.o. gotówką. Kwota stanowiąca iloczyn ostatecznej liczby Akcji, które zostaną nabyte od poszczególnych Akcjonariuszy w ramach niniejszej Oferty oraz ceny zakupu, podanej w pkt 2 nin. Oferty, zostanie przekazana na rachunek bankowy Akcjonariusza, określony w umowie sprzedaży akcji.</li>
          <li>Nabycie Akcji następuje z chwilą dokonania w Rejestrze Akcjonariuszy wpisu FABRYKA MACHIN sp. z o.o. jako nabywcy.</li>
          <li>Środki przeznaczone na nabycie Akcji będą pochodziły ze środków własnych FABRYKA MACHIN sp. z o.o.</li>
          <li>W przypadku większej liczby zgłoszonych do zbycia Akcji, niż przewiduje to Oferta, FABRYKA MACHIN sp. z o.o. podejmie działania, aby zakupić Akcje od poszczególnych akcjonariuszy proporcjonalnie do ogólnej liczby akcji oferowanych przez wszystkich Akcjonariuszy. W takim wypadku liczba Akcji wynikająca z Ofert Sprzedaży będzie zaokrąglana w dół, do liczby całkowitej. W przypadku, gdy po przeprowadzeniu proporcjonalnej redukcji pozostaną ułamkowe liczby Akcji, Akcje te będą nabywane kolejno, po jednej, począwszy od ofert sprzedaży opiewających na największą liczbę Akcji do ofert sprzedaży opiewających na najmniejszą liczbę Akcji.
          <li>Zaakceptowane zostaną wyłącznie oferty sprzedaży Akcji złożone zgodnie z niniejszymi warunkami Oferty.
          <li>Wskazuje się terminy czynności podejmowanych przez FABRYKA MACHIN sp. z o.o.:
            <ol type="a">
                <li>Termin ogłoszenia Oferty: 29.12.2023r.</li>
                <li>Termin rozpoczęcia przyjmowania ofert sprzedaży od Akcjonariuszy: od 08.01.2024r.</li>
                <li>Termin zakończenia przyjmowania Ofert Sprzedaży: do 31.01.2024r.</li>
                <li>Planowana ostateczna data podjęcia przez FABRYKA MACHIN sp. z o.o. decyzji o akceptacji ofert sprzedaży lub o ewentualnej redukcji: do 12.02.2024r.</li>
                <li>Przewidywany dzień zawarcia umów sprzedaży Akcji: do 29.02.2024r.</li>
            </ol>
        </li>
        <li>FABRYKA MACHIN sp. z o.o. zastrzega sobie prawo do zmiany terminów wskazanych w niniejszej Ofercie. W przypadku zmiany terminów, stosowna informacja zostanie podana do wiadomości Akcjonariuszy w formie, w jakiej Oferta została ogłoszona.</li>
        <li>Akcjonariusze uprawnieni do sprzedaży Akcji w ramach odpowiedzi na niniejszą Ofertę mogą składać ofertę sprzedaży Akcji:
            <ol type="a">
                <li>Korespondencyjnie pod adres: Aleje Jerozolimskie nr 65/79, 00-697 Warszawa.</li>
                <li>W formie elektronicznej opatrzonej kwalifikowanym podpisem elektronicznym, pod adres e-mail: contact@xterra.group.</li>
            </ol>
        </li>
        <li>W odpowiedzi na ofertę sprzedaży Akcji FABRYKA MACHIN sp. z o.o. poinformuje Akcjonariusza o akceptacji oferty sprzedaży (a tym samym przedstawi projekt umowy sprzedaży Akcji) lub o braku akceptacji oferty sprzedaży.</li>
        <li>Akcjonariusze mogą w trakcie okresu przyjmowania ofert sprzedaży Akcji składać dowolną liczbę ofert sprzedaży Akcji.</li>
        <li>Złożenie oferty sprzedaży Akcji musi być bezwarunkowe i nieodwołalne, nie może zawierać jakichkolwiek zastrzeżeń oraz wiąże osobę składającą (Akcjonariusza) do dn. 29.02.2024r. Wszelkie konsekwencje, z nieważnością oferty sprzedaży Akcji włącznie, wynikające ze złożenia oferty sprzedaży Akcji niezgodnie z nin. Ofertą ponosi Akcjonariusz.</li>
        <li>Akcjonariusze Spółki nie powinni traktować niniejszej Oferty jako porady inwestycyjnej, prawnej ani podatkowej. W sprawach związanych z Ofertą, Akcjonariusze Spółki powinni skorzystać z porady doradców inwestycyjnych, prawnych i podatkowych.</li>
        <li>Niniejsza Oferta zostanie dodatkowo opublikowana na stronie internetowej www.xterra.group</li>
        <li>Wszelkie dodatkowe informacje na temat procedury odpowiedzi na niniejszą Ofertę można uzyskać w FABRYKA MACHIN sp. z o.o. zgodnie z danymi:
            <ol type="a">
                <li>Adres e-mail: contact@xterra.group</li>
                <li>Tel.:+48 665369369, +48 782 519 807</li>
                <li>Adres do korespondencji: Aleje Jerozolimskie nr 65/79 LIM 19p, 00-697 Warszawa.</li>
            </ol>
        </li>
    </ol>
      </i></p>
      <p class="text-right"><i>
        Za FABRYKA MACHIN sp. z o.o. z siedzibą w Warszawie<br>_________________________________________________<br>
      </i></p>

      <h2 class="text-right font-weight-light" style="font-family:Allura"><i>Marcin Długołęcki </i> – Prezes Zarządu</h2>

    </v-col>
  </v-container>
</template>

<script>
  export default {
    name: 'HomeAbout',
  }
</script>
